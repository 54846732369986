//inspiration/source from https://www.gatsbyjs.com/docs/how-to/routing/client-only-routes-and-user-authentication

import { navigate } from 'gatsby-link';
import React, { FunctionComponent, useEffect } from 'react';
import Layout from "../components/Layout";

import theme from '../config/Theme'

export default function index() {

    useEffect(() => {
        navigate("/products")
    },[]);

    return (
        <Layout allowAnonymous={false}>
            
        </Layout>
    )
}